import React from "react"
import styled, { keyframes } from 'styled-components'
import media from '../styles/media'

import Brand from '../images/brand.webp'

const LoaderAni = keyframes`
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(6vh);
  }
`
const LoaderAniMob = keyframes`
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(6.6vh);
  }
`

const LoaderMove = keyframes`
  0% {
    transform: translateY(46vh);
  }
  100% {
   transform: translateY(52vh);
  }
`

const LoadCon = styled.div `
  height: 100vh;
  width: 100vw;
  background-color: #64BF60;
  color: white;
  z-index: 999;
  position: fixed;
  top: 0;
  .hero-image {
    width: 50vw;
    position: absolute;
    top: 45vh;
    left: 25vw;
    opacity: 1;
    animation: ${LoaderAniMob} 1.5s ease forwards;
    animation-delay: 2s;
  }
  .over {
    background-color: rgba(100, 191, 96, 0.7);
    width: 50vw;
    height: 9vh;
    position: absolute;
    transform: translateY(46vh);
    left: 25vw;
    animation: ${LoaderMove} 3s ease forwards;
  }
  .hider {
    background-color: rgba(100, 191, 96);
    width: 50vw;
    height: 9vh;
    position: absolute;
    top: 51.5vh;
    left: 25vw;
    z-index: 6;
  }
  p {
    font-size: 4vw;
    position: absolute;
    top: 48vh;
    left: 43vw;
    font-size: 3vw;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  &.disable {
    transition: 500ms ease-out;
    /* z-index: -10; */
    transform: translateY(-100vh);
    p, .over, .hider {
      opacity: 0;
    }
    .hero-image {
      opacity: 0;
    }
  }
  ${media.mobileL`
    .hero-image {
      width: 25vw;
      position: absolute;
      top: 44vh;
      left: 38vw;
    }
  `}
  ${media.laptop`
    .hero-image {
      height: 30px;
      width: auto;
      position: absolute;
      top: 47vh;
      left: 43vw;
      opacity: 1;
      animation: ${LoaderAni} 1.5s ease forwards;
      animation-delay: 3s;
    }
    .over {
      background-color: rgba(100, 191, 96, 0.7);
      width: 220px;
      height: 40px;
      height: 7vh;
      position: absolute;
      transform: translateY(46vh);
      left: 43vw;
      animation: ${LoaderMove} 3s ease forwards;
      animation-delay:1s;
    }
    .hider {
      background-color: rgba(100, 191, 96);
      width: 220px;
      height: 40px;
      position: absolute;
      top: 52vh;
      left: 43vw;
      z-index: 6;
    }
    &.disable {
      transition: 500ms ease-out;
      /* z-index: -10; */
      transform: translateX(-100vw);
      height: 100vh;
      p, .over, .hider {
        opacity: 0;
      }
      .hero-image {
        opacity: 0;
      }
    }
  `}
`

const LoaderDiv = props => {

  return (
    <LoadCon className={props.active ? "disable" : null}>
      <img src={Brand} alt="Loading" className="hero-image" />
      <div className="over"></div>
      <div className="hider"></div>
    </LoadCon>
  )
}

export default LoaderDiv
