import { Link } from "gatsby"
import React, { useState, useContext, useRef } from "react"
import { Reveal, Tween, PlayState } from 'react-gsap'
import LoadContext from '../context/loadContext'

import styled from 'styled-components'
import media from '../styles/media'
import Logo from '../images/logo.webp'
import BrandMark from '../images/colour-brand.webp'
import BrandMark2 from '../images/brand.webp'
import MenuImg from '../images/menubg.png'

const HeaderCon = styled.header`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0;
  transition: all 0.5s ease-out; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 9;

  &.scroll-up {
    top: 0;
  }

  &.scroll-down {
    top: -70px;
  }

  #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 30px;
  height: 30px;
  position: relative;
  /* margin: 2em 0 0 15px; */
  margin: 13px auto 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 9px;
}

#nav-icon1 span:nth-child(3) {
  top: 18px;
}

  .brandmark, .contact, .address, .social {
    display: none;
    a {
      text-decoration: none;
      color: #404040;
    }
  }

  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }

  &.hidden {
    width: 100vw;
    height: 70px;
    .logo {
      opacity: 1;
      img {
        transition: all 0.5s ease-out; 
        margin: 23px 5px;
        width: auto;
        height: 25px;
      }
    }

    .menuItems, .menu-img {
    display: none;
    }

    .image {
      p {
        display: none;
      }
    }
  }

  &.showing {
    width: 100vw;
    height: 115vh;
    position: fixed;
    background-color: white;
    .logo {   
      opacity: 1;
      img {
        position: absolute;
        transition: all 0.5s ease-out; 
        margin: 23px 5px;
        width: auto;
        height: 25px;
      }
    }

    #nav-icon1 span:nth-child(1) {
      top: 10px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    #nav-icon1 span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    #nav-icon1 span:nth-child(3) {
      top: 10px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }

    .nav {
      width: 100vw;
      padding: 0 5vw;
    }

    .image {
      p {
        font-size: 25vw;
        color: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: 48vh;
        left: 20vw;
        display:  block;
      }
    }

    .contact, .address, .social {
      display: block;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 40px;
      p {
        margin-bottom: 10px;
      }
      a {
        text-decoration: none;
        color: #404040;
        transition: all .3s ease;
      }
    }
  }

  .menuItems {
    display: inline-grid;
    margin: 15vh 0 5vh;
    opacity: 1;
    a {
      margin-bottom: 20px;
      text-decoration: none;
      color: black;
      font-size: 28px;
      line-height: 32px;
      width: fit-content;
    }
    .header-image {
      display: none;
    }
  }
    .menu-img {
     display: none;
    }


  button {
    width: 70px;
    height: 70px;
    /* height: 10vh;
    margin-top: -10px; */
    background-color: transparent;
    border: none;
    &:focus {
      border: none;
      outline: transparent;
    }
    img {
      margin: 0 2.5px;
    }
  }

  .background {
    width: 0vw;
    z-index:-999;
    left: 100vw;
    opacity: 0;
    position: absolute;
    transition: opacity .3s 0s ease-out, z-index 0s 1s;
    margin: 0;
  }

  .divider {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height:70px;
    z-index: -1;
    background-color: #64BF60;
  }

  ${media.mobileL`
  display: flex;
    .menuItems {
      margin: 26vh 0 10vh;
      a {
        font-size: 22px;
        line-height: 24px;
      }
    }
     &.showing {
      .contact, .address, .social {
        display: block;
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 20px;
      }
      .contact {
        bottom: 53vh;
        left: 55vw;
      }
      .address {
        bottom: 36vh;
        left: 55vw;
      }
      .social {
        bottom: 70vh;
        left: 55vw;
      }
     }
  `}

  ${media.laptop`
    height: 100vh;
    transition: all 1s ease-out; 
    position: fixed;
    z-index: 9;
    display: block;
    padding: 0;
    box-shadow: none;

    .mobile {
      display: none;
    }
    .desktop {
      display: block;
  }

    #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
      width: 40px;
      height: 40px;
      position: relative;
      margin: 0;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    #nav-icon1 span:nth-child(1) {
      top: 12px;
    }

    #nav-icon1 span:nth-child(2) {
      top: 24px;
    }

    #nav-icon1 span:nth-child(3) {
      top: 36px;
    }

    .horizontal {
      color: white;
      transform: rotate(90deg);
      position: absolute;
      bottom: 90px;
      left: -40px;
      font-size: 10px;
      line-height: 13px;
      width: 150px;
      margin: 0;
    }

    button {
      width: 70px;
      height: 10vh;
      position: absolute;
      left: 16px;
      top: 48vh;
      background-color: transparent;
      border: none;
      &:focus {
        border: none;
        outline: transparent;
      }
      img {
        margin: 0 2.5px;
      }
    }

    &.hidden {
      width: 70px;
      height: 100vh;
      transition: width .5s ease;
      transition-delay: .1s;

      .logo {
        opacity: 1;
        img {
          transition: all 0.5s ease-out; 
          margin: 20px 15px;
          width: 40px;
          height: 40px;
        }
      }

      .background {
        opacity: 0;
        transition: opacity .5s 0s ease-out, z-index 0s 1s;
        transition-delay: 0;
        left: 100vw;
        z-index: -99;
        width: 0;
      }

      button {
        left:  14px;
      }

      .brandmark, .contact, .address, .social {
        display: none;
      }

      .menuItems {
        opacity: 1;
        .header-image {
          display: block;
          position: fixed !important;
          left: 2vw;
          top: 5vh;
          width: 96vw;
          height: 90vh;
          z-index: -1;
          opacity: 0;
          display: none;
          transition: all .5s ease;
          &.hovered {
            opacity: 0.8;
          }
        }
      }
    }

    &.showing {
      transition-delay: 0s;
      width: 100vw;
      height: 100vh;
      background-color: white;
      border-right: none;

      .menuItems {
        a {
          text-decoration: none;
          font-size: 34px;
          line-height: 36px;
          font-weight: 500;
          color: #000000;
          transition: color .25s ease-in;
          &:hover {
            color: #64BF60;
          }
        }
      }

      .contact, .address, .social { 
        position: relative;
        left: 0;
        font-size: 14px;
        line-height: 16px;
        bottom: auto;
      }

      .background {
        display:block;
        width: 100vw;
        height: 100vh; 
        background-color:rgba(0, 0, 0, 0.3);
        transition: opacity .5s 0s ease-out, z-index 0s 1s;
        position: absolute;
        top: 0;
        left: 0;
        z-index:-1;
        opacity: 1;
      }

      .nav {
        width: 100vw;
        padding: 0;
        z-index:9;
      }

       #nav-icon1 span:nth-child(1) {
        top: 27px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      #nav-icon1 span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      #nav-icon1 span:nth-child(3) {
        top: 27px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
  
      .logo {
        opacity: 1;
        img {
          position: absolute;
          transition: all 0.5s ease-out; 
          margin: 20px 15px;
          width: auto;
          height: 40px;
        }
      }

      button {
        left:  16px;
      }
    }

    .nav {
      display: grid;
      height: 100vh;
      grid-template-columns: 0.25fr 0.3fr 1fr 1fr 1.25fr;
      grid-template-rows:  10vh 60vh 15vh 15vh;
    }

    .divider {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      background-color: white;
      width: 70px;
      height:100vh;
      z-index: -1;
      background-color: #64BF60;
    }

    .brandmark {
      grid-column: 2/5;
      grid-row: 1/2;
      display: block;
      margin: 27px 0 0;
      margin-top: 27px;
      margin-left: 20px;
      img {
        height: 30px;
      }
    }

    .social {
      grid-column: 3/4;
      grid-row: 3/4;
    }

    .contact {
      grid-column: 3/4;
      grid-row: 4/5;
    }

    .address {
      grid-column: 4/5;
      grid-row: 4/5;
    }

    .contact, .address, .social {
      display: block;
      font-size: 16px;
      line-height: 18px;
      bottom: auto;      
      p {
        margin-bottom: 15px;
      }
      a {
        text-decoration: none;
        color: #404040;
        transition: all .3s ease;
        &:hover {
          color: #64BF60;
        }
      }
    }

    .menuItems {
      grid-column: 3/5;
      grid-row: 2/3;
      margin: 50px 0;
      opacity: 1;
      .header-image {
        display: block;
        position: fixed !important;
        left: 2vw;
        top: 5vh;
        width: 96vw;
        height: 90vh;
        z-index: -1;
        opacity: 0;
        transition: all .5s ease;
        &.hovered {
          opacity: 0.8;
        }
      }
    }

    .menu-img {
      display: block;
      grid-column: 5/6;
      grid-row: 1/4;
    }

    .play {
      position: absolute;
      bottom: 5vh;
      left: 10vh;
    }
  `}

  ${media.laptopL`
    .nav {
      grid-template-rows:  15vh 50vh 15vh 15vh;
    }
  `}
`

// const BgButton= styled.div`
//   width: 40vw;
//   height: 100vh;
//   background-image:none;
//   position: absolute;
//   top:0;
//   right: 100vw;
//   z-index:-1;
//   transition: right .5s .5s ease-in background-image .5s .3s ease-in, z-index 0s 1s;
//   &.showing {
//     right: 0;
//     background-image: url(${MenuImg});
//     transition: right .5s .5s ease-in background-image .5s 0s ease-in, z-index 0s 0s;
//     z-index: 3;
//   }
// `

const Header = () => {
  const [open, setOpen] = useState(false)
  const [pageItem, setPageItem] = useState('')
  const loading = useContext(LoadContext)
  const [statePlay, setStatePlay] = useState(PlayState.stop)

  const [playing, setPlaying] = useState(false)

  const headRef = useRef(null)

  const closeMenu = () => {
    if (open) {
      setPlaying('reverse')
        setStatePlay(PlayState.reverse)
      setTimeout(() => {
        setOpen(!open)
        setPlaying('stop')
        setStatePlay(PlayState.stop)
      }, 500);
    } else {
      setOpen(!open)
        setStatePlay(PlayState.play)
      setPlaying('play')
    }
  }

  if (!loading.load) {
  console.log()

    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
      if (!headRef.current.classList.contains('showing')) {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 100) {
        headRef.current.classList.remove(scrollUp);
        return;
      }
      
      if (currentScroll > lastScroll && !headRef.current.classList.contains(scrollDown)) {
        // down
        headRef.current.classList.remove(scrollUp);
        headRef.current.classList.add(scrollDown);
      } else if (currentScroll < lastScroll && headRef.current.classList.contains(scrollDown)) {
        // up
        headRef.current.classList.remove(scrollDown);
        headRef.current.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    }
    });
  }

  return (
    <>
    <HeaderCon open={open} className={open ? 'showing' : 'hidden'} id="headerDiv" ref={headRef}>
      {!loading.load && (
        <Reveal>
          <Tween from={{ opacity: 0 }} duration={.5}  delay={.5}>
            <div className="logo">
              <Link className="desktop" to="/"><img src={Logo} alt="logo" width={40} height={40} /></Link>
              <Link className="mobile" to="/"><img src={BrandMark2} alt="logo" /></Link>
            </div>
          </Tween>
        </Reveal>
      )}
      <div className="divider"></div>

      <div className="nav">
        <Reveal repeat>
          <Tween from={{ opacity: 0, marginTop: '-3vh' }} duration={.3} delay={.9} playState={statePlay}>
            <div className="brandmark"> 
              <img src={BrandMark} alt="logo" width={196} height={25} />
            </div>
          </Tween>
        </Reveal>
        <Reveal repeat>
            <div className="menuItems">
              <Tween from={{ opacity: 0, paddingTop: `2vh` }} duration={.5} delay={.3} playState={statePlay}>
                <Link to="/" onClick={closeMenu} onMouseEnter={() => setPageItem('Home')}>
                  Home
                </Link>
              </Tween>
              <Tween from={{ opacity: 0, paddingTop: `2vh` }} duration={.5} delay={.3} playState={statePlay}>
                <Link to="/about" onClick={closeMenu} onMouseEnter={() => setPageItem('About')}>
                  About
                </Link>
              </Tween>
              <Tween from={{ opacity: 0, paddingTop: `2vh` }} duration={.5} delay={.3} playState={statePlay}>
                <Link to="/services" onClick={closeMenu} onMouseEnter={() => setPageItem('Services')}>
                  Services
                </Link>
              </Tween>
              <Tween from={{ opacity: 0, paddingTop: `2vh` }} duration={.5} delay={.3} playState={statePlay}>
                <Link to="/environment" onClick={closeMenu} onMouseEnter={() => setPageItem('Environment')}>
                  Environment
                </Link>
                </Tween>
              <Tween from={{ opacity: 0, paddingTop: `2vh` }} duration={.5} delay={.3} playState={statePlay}>
                <Link to="/contact" onClick={closeMenu} onMouseEnter={() => setPageItem('Contact')}>
                  Contact
                </Link>
          </Tween>
            </div>
        </Reveal>
        <Reveal repeat>
          <Tween from={{ opacity: 0, marginTop: '5vh' }} duration={.5} delay={.8} playState={statePlay}>
            <div className="social">
              <a href="https://www.facebook.com/ServWorx" rel="noreferrer" target="_blank"><p>Facebook</p></a>
              <a href="https://www.linkedin.com/company/servworxsa/" rel="noreferrer" target="_blank"><p>LinkedIn</p></a>
            </div>
            <div className="contact">
              <a href="tel:0333869281"><p>033 386 9281</p></a>
              <a href="mailto: sales@servworx.co.za"><p>sales@servworx.co.za</p></a>
            </div>
            <div className="address">
              <a href="https://goo.gl/maps/TqGoEn62dfMXLGyt9" rel="noreferrer" target="_blank"> 
                <p>21 Barnsley Road, Camps Drift</p>
                <p>Pietermaritzburg, 2000</p>
              </a>
            </div>
          </Tween>
        </Reveal>
        <div className="menu-img">
          <Reveal repeat>
            <Tween from={{ opacity: 0 }} duration={.5} delay={.8} playState={statePlay}>
              <img src={MenuImg} alt="menu image" />
            </Tween>
          </Reveal>
        </div>
      </div>

      {!loading.load && (
        <button onClick={closeMenu}>
          <Reveal>
            <Tween from={{ opacity: 0 }} duration={.5} delay={1.1} >
              <div id="nav-icon1">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Tween>
          </Reveal>
        </button>
      )}

    </HeaderCon>
      {/* <BgButton className={open ? 'showing' : 'hidden'} onClick={closeMenu}></BgButton> */}
      </>
  )
}

export default Header