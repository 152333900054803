import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import LoadContext from '../context/loadContext'

import Header from "./header"
import LoaderDiv from "./page-loader"
import "./layout.css"

const Layout = ({ location, children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [load, setLoad] = useState(true);

  const updateLoadVisible = () => {
    setLoad(false)
  }

  useEffect(() => {
    setTimeout(function() { 
      setHasLoaded(true)
      updateLoadVisible()
    }, 2500)
  })
  return (
    <>
      <div>
        <LoadContext.Provider value={{ load, updateLoadVisible }}>
          <LoaderDiv active={hasLoaded} />
          <Header />
          {children}
        </LoadContext.Provider>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
